
import api from "@/base/utils/request";

// 获取问题数量
export const contentCount = data => {
  return api({
    url: "/admin/consult/ConsultStats/contentCount",
    method: "post",
    data,
    notCancel: true
  });
};
// 获取回答数量
export const commentCount = data => {
  return api({
    url: "/admin/consult/ConsultStats/commentCount",
    method: "post",
    data,
    notCancel: true
  });
};
// 获取回答人数
export const commentUserCount = data => {
  return api({
    url: "/admin/consult/ConsultStats/commentUserCount",
    method: "post",
    data,
    notCancel: true
  });
};
// 提问最多的分类
export const mostContentCategory = data => {
  return api({
    url: "/admin/consult/ConsultStats/mostContentCategory",
    method: "post",
    data,
    notCancel: true
  });
};
// 已审核
export const checkedCount = data => {
  return api({
    url: "/admin/consult/ConsultStats/checkedCount",
    method: "post",
    data,
    notCancel: true
  });
};
// 待审核
export const checkingCount = data => {
  return api({
    url: "/admin/consult/ConsultStats/checkingCount",
    method: "post",
    data,
    notCancel: true
  });
};
// 未通过
export const uncheckCount = data => {
  return api({
    url: "/admin/consult/ConsultStats/uncheckCount",
    method: "post",
    data,
    notCancel: true
  });
};
// 回答统计
export const commentStats = data => {
  return api({
    url: "/admin/consult/ConsultStats/commentStats",
    method: "post",
    data,
    notCancel: true
  });
};
// 问题统计
export const contentStats = data => {
  return api({
    url: "/admin/consult/ConsultStats/contentStats",
    method: "post",
    data,
    notCancel: true
  });
};
// 问题分类统计
export const contentCategoryStats = data => {
  return api({
    url: "/admin/consult/ConsultStats/contentCategoryStats",
    method: "post",
    data,
    notCancel: true
  });
};
// 问答用户统计
export const consultUserStats = data => {
  return api({
    url: "/admin/consult/ConsultStats/consultUserStats",
    method: "post",
    data,
    notCancel: true
  });
};
